@charset "utf-8";

//
// File Name   : fonts.scss
// Description : Fonts setting
//

// @import url(//fonts.googleapis.com/css?family=Montserrat:400,700);
// @import url(//fonts.googleapis.com/earlyaccess/notosansjapanese.css);
// @import url('//fonts.googleapis.com/css?family=Roboto:700,400');

// @font-face {
//   font-family: 'YakuHanJP';
//   font-style: normal;
//   font-weight: 100;
//   src: url(../fonts/YakuHanJP/YakuHanJP-Thin.woff2) format('woff2'),
//   url(../fonts/YakuHanJP/YakuHanJP-Thin.woff) format('woff'),
//   url(../fonts/YakuHanJP/YakuHanJP-Thin.eot);
// }
// @font-face {
//   font-family: 'YakuHanJP';
//   font-style: normal;
//   font-weight: 200;
//   src: url(../fonts/YakuHanJP/YakuHanJP-Light.woff2) format('woff2'),
//   url(../fonts/YakuHanJP/YakuHanJP-Light.woff) format('woff'),
//   url(../fonts/YakuHanJP/YakuHanJP-Light.eot);
// }
// @font-face {
//   font-family: 'YakuHanJP';
//   font-style: normal;
//   font-weight: 300;
//   src: url(../fonts/YakuHanJP/YakuHanJP-DemiLight.woff2) format('woff2'),
//   url(../fonts/YakuHanJP/YakuHanJP-DemiLight.woff) format('woff'),
//   url(../fonts/YakuHanJP/YakuHanJP-DemiLight.eot);
// }

@font-face {
  font-family: 'YakuHanJP';
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/YakuHanJP/YakuHanJP-Regular.woff2) format('woff2'),
  url(../fonts/YakuHanJP/YakuHanJP-Regular.woff) format('woff'),
  url(../fonts/YakuHanJP/YakuHanJP-Regular.eot);
}
@font-face {
  font-family: 'YakuHanJP';
  font-style: normal;
  font-weight: bold;
  src: url(../fonts/YakuHanJP/YakuHanJP-Medium.woff2) format('woff2'),
  url(../fonts/YakuHanJP/YakuHanJP-Medium.woff) format('woff'),
  url(../fonts/YakuHanJP/YakuHanJP-Medium.eot);
}

// @font-face {
//   font-family: 'YakuHanJP';
//   font-style: normal;
//   font-weight: 700;
//   src: url(../fonts/YakuHanJP/YakuHanJP-Bold.woff2) format('woff2'),
//   url(../fonts/YakuHanJP/YakuHanJP-Bold.woff) format('woff'),
//   url(../fonts/YakuHanJP/YakuHanJP-Bold.eot);
// }
// @font-face {
//   font-family: 'YakuHanJP';
//   font-style: normal;
//   font-weight: 900;
//   src: url(../fonts/YakuHanJP/YakuHanJP-Black.woff2) format('woff2'),
//   url(../fonts/YakuHanJP/YakuHanJP-Black.woff) format('woff'),
//   url(../fonts/YakuHanJP/YakuHanJP-Black.eot);
// }

// @font-face {
//     font-family: 'Noto Sans Japanese';
//    font-style: normal;
//    font-weight: 400;
//    src: url('//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Regular.woff2') format('woff2'),
//         url('//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Regular.woff') format('woff'),
//         url('//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Regular.otf') format('opentype');
// }

// @font-face {
//     font-family: 'Noto Sans Japanese';
//    font-style: normal;
//    font-weight: bold;
//    src: url('//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Medium.woff2') format('woff2'),
//         url('//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Medium.woff') format('woff'),
//         url('//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Medium.otf') format('opentype');
// }

// @font-face {
//     font-family: 'Noto Sans Japanese';
//    font-style: normal;
//    font-weight: 700;
//    src: url('//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Bold.woff2') format('woff2'),
//         url('//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Bold.woff') format('woff'),
//         url('//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Bold.otf') format('opentype');
// }
